import React, { FC, useEffect, useState } from 'react';

import { Card, SearchBar, Space } from 'antd-mobile';
import { ClientJson } from './types';
import { useNavigate } from 'react-router-dom';
import { useNavbar } from '../hooks/use-navbar';
import { SearchOutline } from 'antd-mobile-icons';
import ClientList from './ClientList';

const Home: FC = () => {
  const navigate = useNavigate();
  const navbar = useNavbar();
  const [search, setSearch] = useState('');
  const [showSearch, setShowSearch] = useState(false);

  const onClickSearch = () => {
    setShowSearch((showSearch) => !showSearch);
  };

  useEffect(() => {
    if (navbar) {
      console.log('set Navbar');
      navbar.setTitle('Clients');
      navbar.setBackArrow(false);
      navbar.setRight(
        <div style={{ fontSize: 24 }}>
          <Space style={{ '--gap': '16px' }}>
            <SearchOutline onClick={onClickSearch} />
          </Space>
        </div>
      );
    }
  }, []);

  return (
    <>
      {showSearch && (
        <Card className="dark-background">
          <SearchBar placeholder="Client" onChange={(val) => setSearch(val)} onClear={() => setSearch('')} />
        </Card>
      )}
      <ClientList
        search={search}
        onChooseClient={(client: ClientJson) => {
          navigate(`/client/${client.id}`);
        }}
      />
    </>
  );
};
export default Home;
