import React, { FC } from 'react';

const IconeVehicle: FC<{ isSelected?: boolean; title?: string; backgroundColor?: string; handleClick?: () => void }> = ({
  isSelected,
  title,
  backgroundColor,
  handleClick
}) => {
  if (!backgroundColor) {
    backgroundColor = '#bdbdbd';
  }
  if (!title) {
    title = '';
  }
  let fontSize = '12px';
  if (title.length > 4) {
    fontSize = '10px';
  }
  if (title.length > 6) {
    fontSize = '9px';
  }
  return (
    <div
      onClick={() => handleClick && handleClick()}
      style={{
        backgroundColor: isSelected ? '#3f51b5' : backgroundColor,
        margin: '8px',
        fontSize: fontSize,
        width: '40px',
        height: '40px',
        display: 'flex',
        overflow: 'hidden',
        position: 'relative',
        alignItems: 'center',
        flexShrink: '0',
        lineHeight: '1',
        userSelect: 'none',
        borderRadius: '50%',
        justifyContent: 'center',
        color: '#fafafa'
      }}
    >
      {title.toUpperCase().slice(0, 7)}
    </div>
  );
};
export default IconeVehicle;
