import React, { useEffect } from 'react';

import { AutoCenter, Button, Dialog, Form, Input, Tag } from 'antd-mobile';
import { UserJson } from './types';
import { useNavigate } from 'react-router-dom';
import { useNavbar } from '../hooks/use-navbar';
import { useAuth } from '../hooks/use-auth';
import { ServiceWorkerUpdaterProps, withServiceWorkerUpdater } from '@3m1/service-worker-updater';

const Account = (props: ServiceWorkerUpdaterProps) => {
  const navbar = useNavbar();
  const navigate = useNavigate();
  const auth = useAuth();
  const { onLoadNewServiceWorkerAccept } = props;

  useEffect(() => {
    if (navbar) {
      navbar.setTitle('Mon compte');
      navbar.setBackArrow(false);
      navbar.setRight();
    }
  }, [navbar]);

  const onFinish = (values: any) => {
    Dialog.alert({
      content: <pre>{JSON.stringify(values, null, 2)}</pre>
    });
  };

  if (!auth?.user) {
    return <></>;
  }

  return (
    <>
      <Form
        initialValues={{
          title: (auth.user as UserJson).title,
          email: (auth.user as UserJson).email,
          phone: ''
        }}
        name="form"
        onFinish={onFinish}
        footer={
          <>
            {/* <Button block type="submit" color="primary" size="large">
              Enregistrer
            </Button> */}
            <Button
              block
              color="danger"
              size="large"
              onClick={async () => {
                await auth.signout();
                navigate('/');
              }}
            >
              Deconnexion
            </Button>
          </>
        }
      >
        <Form.Header>Infos</Form.Header>
        <Form.Item name="title" label="title" rules={[{ required: true }]} disabled>
          <Input />
        </Form.Item>
        <Form.Item name="email" label="email" rules={[{ required: true }]} disabled>
          <Input />
        </Form.Item>
        <Form.Item name="phone" label="telephone" rules={[{ required: true }]} disabled>
          <Input />
        </Form.Item>
      </Form>
      <AutoCenter>
        <Tag color="primary" fill="outline">
          {process.env.REACT_APP_NAME} - {process.env.REACT_APP_VERSION}
        </Tag>
      </AutoCenter>
      <AutoCenter>
        <Button
          size={'small'}
          onClick={() => {
            onLoadNewServiceWorkerAccept();
          }}
        >
          Force Update
        </Button>
      </AutoCenter>
    </>
  );
};
export default withServiceWorkerUpdater(Account);
