import { ConfigProvider } from 'antd-mobile';
import frFR from 'antd-mobile/es/locales/fr-FR';

import React from 'react';

import './App.css';
import Main from './components/Main';
import Updater from './components/Updater';
import ProvideAuth from './hooks/use-auth';
import ProvideNavbar from './hooks/use-navbar';

const App = () => {
  return (
    <>
      <ConfigProvider locale={frFR}>
        <ProvideAuth>
          <ProvideNavbar>
            <Main className="child-main" />
          </ProvideNavbar>
        </ProvideAuth>
        <Updater />
      </ConfigProvider>
    </>
  );
};

export default App;
