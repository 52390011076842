import React, { FC, useCallback, useEffect, useState } from 'react';

import { FormValues, InterventionEditJson, InterventionJson, PrestationEnum, RateJson } from './types';
import { useNavbar } from '../hooks/use-navbar';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useClient } from '../hooks/use-client';
import { LoadingComponent } from './Loading';
import { Button, Empty, Form, Input, NoticeBar, Selector, Space, Switch, TextArea, Toast } from 'antd-mobile';
import { SelectorOption } from 'antd-mobile/es/components/selector';
import { PopupValidation } from './PopupValidation';
import { DatePicker } from 'antd-mobile/es/components/date-picker/date-picker';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}
function findPrestationCost(costs: RateJson[], vehicle_type: string | undefined | null): RateJson | undefined {
  if (!vehicle_type) {
    return;
  }
  return costs.filter((c) => c.title === vehicle_type)[0];
}

const NewIntervention: FC = () => {
  const navbar = useNavbar();
  const navigate = useNavigate();
  const query = useQuery();

  const { clientId } = useParams<{ clientId: string }>();
  const { interventionId } = useParams<{ interventionId: string }>();

  const { client, costs, loading: clientLoading } = useClient(clientId);

  const initialIntervention: InterventionEditJson = {
    interior: false,
    exterior: false,
    disinfection: false,
    decontamination: false,
    package: false,
    comment: '',
    driver: '',
    photo_plate: query.get('file') || '',
    immatriculation: query.get('immat') || '',
    parc_number: query.get('parc-number') || '',
    timestamp: new Date().toISOString(),
    prestation_costs: undefined,
    client_id: 0,
    author_id: undefined
  };
  const [intervention, setIntervention] = useState<InterventionEditJson>(initialIntervention);

  const [form] = Form.useForm<FormValues>();
  const [warningVehicleType, setWarningVehicleType] = useState(false);
  const [warningPrestation, setWarningPrestation] = useState(false);
  const [warningVehicleIdent, setWarningVehicleIdent] = useState(false);
  const [showComment, setShowComment] = useState(false);
  const [popinVisible, setPopinVisible] = useState(false);
  const [datePickerVisible, setDatePickerVisible] = useState(false);

  useEffect(() => {
    if (!client || clientLoading || !costs || !costs.length) {
      return;
    }
    if (interventionId) {
      fetch(`${process.env.REACT_APP_BACKEND_HOST}/api2/interventions/${interventionId}`, {
        headers: { version: '' + process.env.REACT_APP_VERSION }
      })
        .then((res) => res.json())
        .then((data) => {
          console.log('intervention fetched', data);
          setIntervention(data);
          form.setFieldsValue({
            ...data,
            vehicle_type: [...[data.prestation_costs?.title || '']],
            prestations: [
              data.interior ? 'interior' : '',
              data.exterior ? 'exterior' : '',
              data.disinfection ? 'disinfection' : '',
              data.decontamination ? 'decontamination' : '',
              data.package ? 'package' : ''
            ]
          });
        })
        .catch((error) => {
          throw Error(error);
        });
    }
    const vehicle_type = findPrestationCost(costs, query.get('type'));
    console.log('look for vehicle type', query.get('type'), vehicle_type, { costs });
    setIntervention((intervention) => ({ ...intervention, prestation_costs: vehicle_type, client_id: client.id }));
    form.setFieldValue('vehicle_type', [vehicle_type?.title]);
  }, [client, costs, query.get('type'), clientLoading, interventionId]);

  useEffect(() => {
    if (!navbar || !client) {
      return;
    }
    const returnToHome = () => {
      navigate('/client/' + client.id);
    };

    navbar.setTitle(client.name);
    navbar.setBackArrow(true);
    navbar.setOnBack(returnToHome);
    navbar.setRight();
  }, [client]);

  if (!client || clientLoading || !costs || !costs.length) {
    return <LoadingComponent />;
  }

  //
  const handleVehicleTypeChange = (vehicle_types: string[]) => {
    setIntervention((intervention) => ({
      ...intervention,
      ...{ interior: false, exterior: false, disinfection: false, package: false, decontamination: false },
      prestation_costs: vehicle_types.length ? findPrestationCost(costs, vehicle_types[0]) : undefined
    }));
    form.setFieldValue('prestations', []);
  };

  const handlePrestationsChange = (prestationsSelected: PrestationEnum[]) => {
    setIntervention((intervention) => ({
      ...intervention,
      ...prestationsSelected.reduce<{
        interior: boolean;
        exterior: boolean;
        disinfection: boolean;
        package: boolean;
        decontamination: boolean;
      }>(
        (acc, cur) => {
          acc[cur] = true;
          return acc;
        },
        { interior: false, exterior: false, disinfection: false, package: false, decontamination: false }
      )
    }));
  };
  const validateForm = (values: FormValues): boolean => {
    setWarningVehicleType(false);
    setWarningVehicleIdent(false);
    setWarningPrestation(false);

    // Check if form valid
    let error = false;
    setWarningVehicleIdent(true);
    if (!intervention.prestation_costs) {
      setWarningVehicleType(true);
      error = true;
    }
    if (!values.immatriculation.trim().length && !values.parc_number.trim().length) {
      error = true;
    }
    if (
      !intervention.decontamination &&
      !intervention.disinfection &&
      !intervention.interior &&
      !intervention.exterior &&
      !intervention.package
    ) {
      setWarningPrestation(true);
      error = true;
    }
    return error;
  };

  const onSave = (values: FormValues) => {
    /** Form validation */
    const error = validateForm(values);

    if (!error) {
      /**
       * author_id: si existe deja, on le garde pour pas reatribuer à un autre agent en cas de modif
       * timestamp pareil, on peut pas le changer en front
       * id quand c'est du patch
       */
      setIntervention((intervention) => ({
        ...intervention,
        comment: form.getFieldValue('comment'),
        driver: form.getFieldValue('driver'),
        immatriculation: form.getFieldValue('immatriculation'),
        parc_number: form.getFieldValue('parc_number')
      }));
      setPopinVisible(true);
    }
  };

  const choices: Array<SelectorOption<PrestationEnum>> = [];
  if (intervention.prestation_costs?.decontamination) {
    choices.push({ label: 'Decont', value: 'decontamination' });
  }
  if (intervention.prestation_costs?.disinfection) {
    choices.push({ label: 'Desinf', value: 'disinfection' });
  }
  if (intervention.prestation_costs?.interior) {
    choices.push({ label: 'Int', value: 'interior' });
  }
  if (intervention.prestation_costs?.exterior) {
    choices.push({ label: 'Ext', value: 'exterior' });
  }
  if (intervention.prestation_costs?.package) {
    choices.push({ label: 'Pack', value: 'package' });
  }

  const initialValues: FormValues = {
    ...intervention,
    vehicle_type: [...[intervention.prestation_costs?.title || '']],
    prestations: [
      intervention.interior ? 'interior' : '',
      intervention.exterior ? 'exterior' : '',
      intervention.disinfection ? 'disinfection' : '',
      intervention.decontamination ? 'decontamination' : '',
      intervention.package ? 'package' : ''
    ]
  };

  console.log({ initialValues });

  return (
    <div className="new-intervention-panel">
      <Form
        initialValues={initialValues}
        form={form}
        style={{ paddingBottom: 55 }}
        layout="horizontal"
        mode="card"
        onFinish={onSave}
        footer={
          <Button block type="submit" color="primary" size="large">
            Enregistrer
          </Button>
        }
      >
        <Form.Header>Date de l'inter. :</Form.Header>
        <Space wrap>
          <>
            <Button
              onClick={() => {
                setDatePickerVisible(true);
              }}
            >
              Modifier
            </Button>
            <DatePicker
              // onClick={() => {
              //   setDatePickerVisible(true);
              // }}
              value={new Date(intervention.timestamp)}
              visible={datePickerVisible}
              onClose={() => {
                setDatePickerVisible(false);
              }}
              precision="day"
              onConfirm={(val) => {
                val.setHours(new Date(intervention.timestamp).getHours());
                val.setMinutes(new Date(intervention.timestamp).getMinutes());
                setIntervention((intervention) => ({
                  ...intervention,
                  timestamp: val.toISOString()
                }));
                setDatePickerVisible(false);
              }}
              confirmText="Ok"
              cancelText="Annuler"
              // renderLabel={labelRenderer}
            >
              {(value) => value?.toLocaleString()}
            </DatePicker>
          </>
        </Space>

        <Form.Header>Le vehicule :</Form.Header>
        <Form.Item name="immatriculation" label="Immatr.">
          <Input placeholder="..." autoCapitalize="characters" autoCorrect="false" />
        </Form.Item>
        <Form.Item name="parc_number" label="N° Parc">
          <Input placeholder="..." autoCapitalize="characters" autoCorrect="false" />
        </Form.Item>
        {warningVehicleIdent && <NoticeBar content="Entrer une immatriculation ou un numéro de parc" color="alert" />}

        <Form.Header />

        <Form.Item name="driver" label="Conducteur">
          <Input placeholder="..." autoCapitalize="characters" autoCorrect="false" />
        </Form.Item>
        <Form.Header />

        <Form.Item name="vehicle_type" required>
          <Selector<string>
            onChange={handleVehicleTypeChange}
            columns={Math.min(costs.length, 3)}
            options={costs.map((c) => {
              return { label: c.title, value: c.title };
            })}
          />
        </Form.Item>
        {warningVehicleType && <NoticeBar content="Choisissez un type de vehicule" color="alert" />}

        <Form.Header>Type de prestation</Form.Header>
        <Form.Item name="prestations" required>
          {intervention.prestation_costs ? (
            <Selector<PrestationEnum>
              key={intervention.prestation_costs.title}
              onChange={handlePrestationsChange}
              multiple={true}
              columns={Math.min(choices.length, 3)}
              options={choices}
            />
          ) : (
            <Empty description="choisir un type de vehicule" />
          )}
        </Form.Item>
        {warningPrestation && <NoticeBar content="Choisissez au moins une prestation" color="alert" />}

        <Form.Header>
          Commentaire <Switch checked={showComment} onChange={(checked) => setShowComment(checked)} />
        </Form.Header>
        {showComment && (
          <Form.Item name="comment">
            <TextArea placeholder="..." />
          </Form.Item>
        )}
      </Form>
      {popinVisible && intervention.prestation_costs && intervention.client_id && (
        <PopupValidation intervention={intervention as InterventionJson} onMaskClick={() => setPopinVisible(false)} />
      )}
    </div>
  );
};

export default NewIntervention;
