import React, { FC, useEffect, useState } from 'react';
import { useNavbar } from '../hooks/use-navbar';
import { Button, FloatingBubble, Image, Selector, Space } from 'antd-mobile';
import { SendOutline, UndoOutline, AddCircleOutline } from 'antd-mobile-icons';
import { Action, ActionSheet } from 'antd-mobile/es/components/action-sheet/action-sheet';
import { useNavigate } from 'react-router-dom';
import Webcam from 'react-webcam';
import ClientList from './ClientList';
import { ClientJson } from './types';

enum Kind {
  Immat = 'immat',
  ParcNumber = 'parc-number'
}

function dataURLtoFile(dataurl: string, filename: string) {
  const arr = dataurl.split(',');
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const mime = arr[0].match(/:(.*?);/)![1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

const uploadPhoto = async (data: string, kind: Kind) => {
  const formData = new FormData();
  formData.append('file', dataURLtoFile(data, 'plate.png'));
  formData.append('kind', kind);

  try {
    const result = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/api2/plates/upload`, {
      headers: { version: '' + process.env.REACT_APP_VERSION },
      body: formData,
      method: 'POST'
    }).then((res) => res.json());
    return result;
  } catch (error) {
    console.error(error);
    return null;
  }
};

type PlateCandidate = {
  plate: string;
  kind: Kind;
  id?: number;
  name?: string;
  display_name?: string;
  vehicle_type?: string;
  filename: string;
};

const Photo: FC = () => {
  const navbar = useNavbar();
  const navigate = useNavigate();

  useEffect(() => {
    if (navbar) {
      navbar.setTitle('Photos');
      navbar.setBackArrow(false);
      navbar.setRight();
    }
  }, [navbar]);

  const [kindOfPlate, setKindOfPlate] = useState<Kind | null>(null);
  const [isShowVideo, setIsShowVideo] = useState(false);
  const [dataUri, setDataUri] = useState('');
  const [candidates, setCandidates] = useState<PlateCandidate[]>([]);
  const [visible, setVisible] = useState(false);
  const [showClientPicker, setShowClientPicker] = useState(false);
  const [selectedPlate, setSelectedPlate] = useState<PlateCandidate>();

  // const startCam = () => {
  //   setIsShowVideo(true);
  // };

  // const stopCam = () => {
  //   if (webcamRef && webcamRef.current) {
  //     const stream = webcamRef.current.stream;
  //     const tracks = stream?.getTracks() || [];
  //     tracks.forEach((track) => track.stop());
  //     setIsShowVideo(false);
  //   }
  // };
  const validateCapture = async (dataUri: string, kind: Kind) => {
    const result = await uploadPhoto(dataUri, kind);
    setCandidates(result);
    setVisible(true);
  };

  const actions: Action[] = [
    ...candidates.map((c) => ({
      text: c.plate,
      key: c.plate,
      description: c.name,
      onClick: () => {
        console.log('load plate', c);
        if (c.id) {
          navigate(`/client/${c.id}/new?${c.kind}=${c.plate}&type=${c.vehicle_type}&file=${c.filename}`);
        } else {
          setShowClientPicker(true);
          setSelectedPlate(c);
        }
      }
    })),
    {
      text: 'Reprendre une photo',
      key: 'retake',
      onClick: () => {
        setKindOfPlate(null);
        setDataUri('');
        setCandidates([]);
        setVisible(false);
      }
    },
    {
      text: 'Saisir à la main',
      key: 'manuel',
      onClick: () => {
        setShowClientPicker(true);
        setSelectedPlate(undefined);
      }
    }
  ];

  if (!kindOfPlate) {
    return (
      <>
        <Space justify="center" align="center" direction="vertical" block style={{ height: '80%' }}>
          <Button block color="primary" size="large" onClick={() => setKindOfPlate(Kind.Immat)}>
            Immatriculation
          </Button>
          <Button block color="primary" size="large" onClick={() => setKindOfPlate(Kind.ParcNumber)}>
            Numéro de parc
          </Button>
        </Space>
      </>
    );
  }
  const options = [
    {
      label: 'Immatriculation',
      value: Kind.Immat
    },
    {
      label: 'Numéro de Parc',
      value: Kind.ParcNumber
    }
  ];
  return (
    <div>
      {!showClientPicker && (
        <>
          <CameraScreenshot
            onValidateCapture={(dataUri) => validateCapture(dataUri, kindOfPlate)}
            onOpenCamera={() => {
              setVisible(false);
            }}
          />
          <Selector
            style={{
              '--border-radius': '100px',
              '--border': 'solid transparent 1px',
              '--checked-border': 'solid var(--adm-color-primary) 1px',
              '--padding': '15px 24px',
              margin: '15px'
            }}
            columns={2}
            options={options}
            value={[kindOfPlate]}
            onChange={(v) => {
              if (v.length) {
                setKindOfPlate(v[0]);
              }
            }}
          />
          <ActionSheet visible={visible} actions={actions} />
        </>
      )}
      {showClientPicker && (
        <ClientList
          search=""
          onChooseClient={(client: ClientJson) => {
            if (selectedPlate) {
              navigate(`/client/${client.id}/new?${kindOfPlate}=${selectedPlate?.plate}&file=${selectedPlate?.filename}`);
            } else {
              navigate(`/client/${client.id}/new`);
            }
          }}
        />
      )}
    </div>
  );
};

const CameraScreenshot: FC<{ onOpenCamera: () => void; onValidateCapture: (dataUri: string) => void }> = ({
  onValidateCapture,
  onOpenCamera
}) => {
  const [dataUri, setDataUri] = useState('');

  function handleTakePhotoAnimationDone(dataUri: string) {
    console.log('takePhoto', dataUri);
    setDataUri(dataUri);
  }

  return (
    <div>
      {dataUri ? (
        <>
          <Image src={dataUri} fit="contain" />
          <div
            style={{
              position: 'absolute',
              bottom: '60px',
              width: '100%'
            }}
          >
            <Space justify="center" block>
              <Button
                loading="auto"
                onClick={() => {
                  setDataUri('');
                  onOpenCamera();
                }}
              >
                <UndoOutline />
                Reprendre
              </Button>
              <Button
                loading="auto"
                onClick={async () => {
                  await onValidateCapture(dataUri);
                }}
              >
                <SendOutline /> Envoyer
              </Button>
            </Space>
          </div>
        </>
      ) : (
        <Camera onCapture={handleTakePhotoAnimationDone} />
      )}
    </div>
  );
};

const Camera: FC<{ onCapture: (dataUri: string) => void }> = ({ onCapture }) => {
  const webcamRef = React.useRef<Webcam>(null);
  const videoConstraints = {
    width: 1024,
    height: 1024,
    facingMode: 'environment'
  };

  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current?.getScreenshot() || '';
    onCapture(imageSrc);
  }, [webcamRef]);

  return (
    <>
      <Webcam ref={webcamRef} audio={false} height="100%" screenshotFormat="image/jpeg" width="100%" videoConstraints={videoConstraints} />
      <FloatingBubble
        axis="x"
        magnetic="x"
        style={{
          '--size': '80px',
          '--initial-position-bottom': '84px',
          '--initial-position-right': '50%',
          '--edge-distance': '2px'
        }}
        onClick={capture}
        className="screenshot-btn"
      >
        <AddCircleOutline fontSize={22} />
      </FloatingBubble>
    </>
  );
};

export default Photo;

/**
 * 
J'arrive sur photo, je choisis plaque ou numéro de parc

  [Plaque]
  [Parc]

--Plaque--
  1. j'affiche la webcam avec un bouton pour prendre la photo
    Webcam
    FloatingButton center

  2. j'affiche la photo prise, avec 2 bouton pour valider ou recommencer

  3. j'affiche la bottomActionSheet avec 
    - les plaques détéctées
      - lorsque je clique sur une plaque avec un client, je vais direct sur newIntervention
      - lorsque je clique sur une plaque sans client, je vais sur une page pour choisir le client, puis sur newIntervention préRempli
      - lorsque je clique sur saisir à la main, je vais sur une page pour choisir le client et rentrer la plaque, puis sur newIntervention préRempli
      - lorque je clique sur reprendre, je réaffiche la caméra

 * 
 */
