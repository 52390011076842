import React, { useState, useContext, createContext, ReactNode } from 'react';
import { UserJson } from '../components/types';

interface AuthContextInterface {
  user: UserJson | null | boolean;
  signin: (email: string, password: string) => void;
  signout: () => Promise<void>;
  autoSignIn: () => void;
  errors: string[];
  isLoading: boolean;
}

const authContext = createContext<AuthContextInterface | null>(null);
// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().

const ProvideAuth = ({ children }: { children: ReactNode }) => {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};
export default ProvideAuth;

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext);
};
// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const [user, setUser] = useState<UserJson | null | boolean>(null);
  const [errors, setErrors] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  // Wrap any Firebase methods we want to use making sure ...
  // ... to save the user to state.
  const signin = (email: string, password: string) => {
    setIsLoading(true);
    fetch(`/auth/login/`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        version: '' + process.env.REACT_APP_VERSION
      },
      body: JSON.stringify({
        username: email,
        password: password
      })
    })
      .then((res) => res.json())
      .then((data) => {
        setIsLoading(false);

        if (data?.user?.email) {
          setUser(data.user);
        } else {
          setErrors(['Identifiants incorrects!']);
        }
      })
      .catch(() => {
        setIsLoading(false);

        setErrors(['Erreur de service !']);
      });
  };

  const signout = () => {
    return fetch(`${process.env.REACT_APP_BACKEND_HOST}/auth/logout`, { headers: { version: '' + process.env.REACT_APP_VERSION } })
      .then((res) => res.json())
      .then(() => {
        setUser(false);
        setErrors([]);
      });
  };

  const autoSignIn = () => {
    fetch(`${process.env.REACT_APP_BACKEND_HOST}/auth/profile`, { headers: { version: '' + process.env.REACT_APP_VERSION } })
      .then((res) => res.json())
      .then((data) => {
        if (!data.user) {
          setUser(false);
        } else {
          setUser(data.user);
        }
      })
      .catch(console.log);
  };

  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.
  // useEffect(() => {
  //   const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
  //     if (user) {
  //       setUser(user);
  //     } else {
  //       setUser(null);
  //     }
  //   });
  //   // Cleanup subscription on unmount
  //   return () => unsubscribe();
  // }, []);
  // Return the user object and auth methods
  return {
    user,
    signin,
    signout,
    autoSignIn,
    errors,
    isLoading
  };
}
