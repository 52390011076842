import React, { useRef, useState } from 'react';
import { withServiceWorkerUpdater, ServiceWorkerUpdaterProps } from '@3m1/service-worker-updater';
import { ActionSheet } from 'antd-mobile';
import { Action, ActionSheetShowHandler } from 'antd-mobile/es/components/action-sheet';
import { LocalStoragePersistenceService } from '@3m1/service-worker-updater';

const Updater = (props: ServiceWorkerUpdaterProps) => {
  const { newServiceWorkerDetected, onLoadNewServiceWorkerAccept } = props;
  const [needUpdate, setNeedUpdate] = useState(false);

  const checkSW = async () => {
    console.log('checkSW');

    const { controller } = navigator.serviceWorker;
    if (controller) {
      // If we have controller, use it's URL to re-register it
      const registrationFromAPI = await navigator.serviceWorker.register(controller.scriptURL);

      // If we could register it and there is a waiting SW, use it
      if (registrationFromAPI?.waiting) {
        setNeedUpdate(true);
      } else {
        console.log('ServiceWorkerRegistration not found and no waiting ServiceWorker found');
      }
    } else {
      console.log('ServiceWorkerRegistration not found and no ServiceWorker detected');
    }
  };

  const handler = useRef<ActionSheetShowHandler>();

  checkSW();

  console.log({ newServiceWorkerDetected });
  console.log({ version: process.env.REACT_APP_VERSION });

  const actions: Action[] = [
    {
      text: "Mettre l'app à jour",
      key: 'update',
      onClick: () => {
        onLoadNewServiceWorkerAccept();
        handler.current?.close();
      }
    }
  ];

  return needUpdate ? (
    <>
      <ActionSheet visible={true} actions={actions} />
    </>
  ) : null; // If no update is available, render nothing
};

export default withServiceWorkerUpdater(Updater, {
  persistenceService: new LocalStoragePersistenceService('CMNET')
});
