import React, { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Features } from './featureflag';
import { UnorderedListOutline, UserOutline, ScanningOutline, FileOutline } from 'antd-mobile-icons';
import { TabBar } from 'antd-mobile';

export const BottomBar: FC<{ features: Features }> = ({ features }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  const tabPlateRecognizer = features.plateRecognizer
    ? [
        {
          key: '/photo',
          title: 'Photo',
          icon: <ScanningOutline />
        }
      ]
    : [];
  const tabSignaturePad = features.signaturePad
    ? [
        {
          key: '/sign',
          title: 'Sign',
          icon: <FileOutline />
        }
      ]
    : [];
  const tabPlanning = features.planning
    ? [
        {
          key: '/planning',
          title: 'Planning',
          icon: <FileOutline />
        }
      ]
    : [];
  const tabs = [
    ...tabPlanning,
    {
      key: '/',
      title: 'Clients',
      icon: <UnorderedListOutline />
    },
    ...tabPlateRecognizer,
    ...tabSignaturePad,

    // {
    //   key: '/search',
    //   title: 'Search',
    //   icon: <SearchOutline />
    // },
    {
      key: '/me',
      title: 'Compte',
      icon: <UserOutline />
    }
  ];

  return (
    <TabBar activeKey={pathname} onChange={(value) => navigate(value)}>
      {tabs.map((item) => (
        <TabBar.Item key={item.key} icon={item.icon} title={item.title} />
      ))}
    </TabBar>
  );
};
