import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, DotLoading, NoticeBar, Popup, Space, Steps, Tag } from 'antd-mobile';
import { ClockCircleOutline, TruckOutline } from 'antd-mobile-icons';
import { Step } from 'antd-mobile/es/components/steps/step';
import { InterventionJson, ValidationEnum } from './types';

type ValidateResult = {
  isAlreadyWashedToday: boolean;
  similarImmat: {
    cnt: number;
    immatriculation: string;
    proximity: number;
  }[];
};

export interface PopupProps {
  onMaskClick: () => void;
  intervention: InterventionJson;
}

export const PopupValidation: FC<PopupProps> = (props) => {
  const { onMaskClick, intervention } = props;
  const navigate = useNavigate();

  const [validating, setValidating] = useState<ValidationEnum>('NONE');
  const [saving, setSaving] = useState(false);
  // const [similarImmat, setSimilarImmat] = useState<{ cnt: number; immatriculation: string; proximity: number }[]>([]);

  const checkValidity = async (intervention: InterventionJson) => {
    setValidating('VALIDATING');
    const result: ValidateResult = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/api2/interventions/validate`, {
      headers: {
        'Content-Type': 'application/json',
        version: '' + process.env.REACT_APP_VERSION
      },
      body: JSON.stringify({
        immatriculation: intervention.immatriculation,
        parc_number: intervention.parc_number,
        client_id: intervention.client_id
      }),
      method: 'POST'
    }).then((res) => res.json());

    if (result.isAlreadyWashedToday) {
      setValidating('SEEN');
    } /*else if (result.similarImmat.length) {
      const listImmatSimilar = result.similarImmat.filter((immat) => immat.proximity > 0.3 && immat.proximity < 1);
      if (listImmatSimilar.length) {
        setSimilarImmat(listImmatSimilar);
        setValidating('LOOKALIKE');
      } else {
        setValidating('VALID');
      }
    }*/ else {
      setValidating('VALID');
    }
  };
  useEffect(() => {
    console.log('check', intervention);
    checkValidity(intervention);
  }, [intervention]);

  const save = (intervention: InterventionJson) => {
    setSaving(true);
    const payload = {
      driver: intervention.driver,
      immatriculation: intervention.immatriculation,
      parc_number: intervention.parc_number,
      interior: intervention.interior,
      exterior: intervention.exterior,
      disinfection: intervention.disinfection,
      decontamination: intervention.decontamination,
      package: intervention.package,
      comment: intervention.comment,
      client_id: intervention.client_id,
      prestation_costs: intervention.prestation_costs,
      photo_plate: intervention.photo_plate,
      timestamp: intervention.timestamp,
      author_id: intervention.author_id
    };
    if (intervention.id) {
      fetch(`${process.env.REACT_APP_BACKEND_HOST}/api2/interventions/${intervention.id}`, {
        headers: {
          'Content-Type': 'application/json',
          version: '' + process.env.REACT_APP_VERSION
        },

        body: JSON.stringify(payload),
        method: 'PATCH'
      })
        .then(() => {
          setSaving(false);
          navigate('/client/' + intervention.client_id);
        })
        .catch(console.log);
    } else {
      fetch(`${process.env.REACT_APP_BACKEND_HOST}/api2/interventions`, {
        headers: {
          'Content-Type': 'application/json',
          version: '' + process.env.REACT_APP_VERSION
        },
        body: JSON.stringify(payload),
        method: 'POST'
      })
        .then(() => {
          setSaving(false);
          navigate('/client/' + intervention.client_id);
        })
        .catch(console.log);
    }
  };
  return (
    <Popup visible onMaskClick={onMaskClick} bodyStyle={{ height: '50vh' }}>
      <Steps
        direction="vertical"
        current={3}
        style={{
          '--title-font-size': '17px',
          '--description-font-size': '15px',
          '--indicator-margin-right': '12px',
          '--icon-size': '32px'
        }}
      >
        <Step
          title={`${intervention.prestation_costs.title} - ${intervention.immatriculation ? intervention.immatriculation : ''} ${
            intervention.parc_number ? ' - ' + intervention.parc_number : ''
          }`}
          description={
            <Space direction="horizontal">
              {/* <Switch onChange={(checked) => setValidating(checked)} /> */}
              {intervention.exterior && (
                <Tag round color="primary">
                  Exter.
                </Tag>
              )}
              {intervention.disinfection && (
                <Tag round color="danger">
                  Desinf.
                </Tag>
              )}
              {intervention.interior && (
                <Tag round color="success">
                  Inter.
                </Tag>
              )}
              {intervention.decontamination && (
                <Tag round color="warning">
                  Decont.
                </Tag>
              )}
              {intervention.package && (
                <Tag round color="default">
                  Pack.
                </Tag>
              )}
            </Space>
          }
          icon={<TruckOutline />}
          status="finish"
        />
        <Step
          title={new Intl.DateTimeFormat('fr', { month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }).format(
            Date.parse(intervention.timestamp)
          )}
          icon={<ClockCircleOutline />}
        />

        {validating === 'VALIDATING' && (
          <Step
            status="process"
            title={
              <>
                <DotLoading color={'primary'} /> checking
              </>
            }
          />
        )}
        {validating === 'VALID' && <Step status="finish" title={<>Ready</>} />}
        {validating === 'SEEN' && (
          <Step
            status="finish"
            title={
              <>
                <NoticeBar color="alert" content="Ce véhicule a déjà été lavé aujourd'hui" />
              </>
            }
          />
        )}
        {/* {validating === 'LOOKALIKE' && (
          <Step
            status="process"
            title={
              <>
                <NoticeBar color="info" content="J'ai trouvé des immat proches" />
                {similarImmat.map((immat) => (
                  <Button
                    key={immat.immatriculation}
                    onClick={() => {
                      form.setFieldsValue({ immatriculation: immat.immatriculation });
                      checkValidity({ ...values, immatriculation: immat.immatriculation });
                    }}
                  >
                    {immat.immatriculation}{' '}
                  </Button>
                ))}
              </>
            }
          />
        )} */}
        {saving ? (
          <Step
            status="process"
            title={
              <>
                <DotLoading color={'primary'} /> Envoi
              </>
            }
          />
        ) : (
          <Step
            status="wait"
            title={
              <Button onClick={() => save(intervention)} color={validating === 'SEEN' ? 'danger' : 'primary'}>
                Envoyer
              </Button>
            }
          />
        )}
      </Steps>
    </Popup>
  );
};
