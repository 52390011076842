import React from 'react';
import { useAuth } from '../hooks/use-auth';
import { Form, Input, Button, NoticeBar } from 'antd-mobile';

const Login = () => {
  const auth = useAuth();

  // constructor(props) {
  //   super(props);
  //   this.state = { username: "", password: "", authflag: 1 };
  //   this.handleChange = this.handleChange.bind(this);
  //   this.handleSubmit = this.handleSubmit.bind(this);
  // }
  // handleChange(event) {
  //   this.setState({
  //     username: event.state.username,
  //     password: event.state.password,
  //   });
  // }
  const onFinish = (values: any) => {
    auth?.signin(values.username, values.password);
    // Dialog.alert({ content: 'Incorrect Credentials!', closeOnMaskClick: true, confirmText: 'ok' });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Form
      name="basic"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      footer={
        <Button block type="submit" color="primary" size="large" loading={auth?.isLoading}>
          Log In
        </Button>
      }
      mode="card"
    >
      <Form.Header>Connexion</Form.Header>

      {auth?.errors.map((e) => (
        <NoticeBar key={e} content={e} color="alert" closeable />
      ))}

      <Form.Item label="Username" name="username" rules={[{ required: true, message: 'Please input your username!' }]}>
        <Input />
      </Form.Item>

      <Form.Item label="Password" name="password" rules={[{ required: true, message: 'Please input your password!' }]}>
        <Input type={'password'} />
      </Form.Item>
    </Form>
  );
};
export default Login;
