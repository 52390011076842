import { InterventionJson } from './components/types';

export function monthYear(intervention: InterventionJson): string {
  const d = Date.parse(intervention.timestamp);
  let month = '';
  let year = '';
  try {
    month = new Intl.DateTimeFormat('fr', { month: 'long' }).format(d);
    year = new Intl.DateTimeFormat('fr', { year: 'numeric' }).format(d);
  } catch (error) {
    console.log(error);
  }
  return `${month} ${year}`;
}
