import { UserJson } from './types';

export type Features = {
  planning: boolean;
  plateRecognizer: boolean;
  signaturePad: boolean;
};

export const hasFeature = (user: UserJson): Features => {
  const hasPlate = true;
  const hasSign = user.email === 'fabien.buchheit@gmail.com';
  return {
    plateRecognizer: hasPlate,
    signaturePad: hasSign,
    planning: user.hasPlanning
  };
};
