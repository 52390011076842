import React, { useState, useEffect, FC } from 'react';

import InterventionListItem from './InterventionListItem';
// import NewIntervention from './NewIntervention';

// import IconeVehicle from './IconeVehicle';

import { ClientJson, InterventionJson, PrestationEnum } from './types';
import { monthYear } from '../utils';

import { Card, Empty, FloatingBubble, IndexBar, List, PullToRefresh, SearchBar, Space } from 'antd-mobile';
import { AddCircleOutline, SearchOutline } from 'antd-mobile-icons';

import { useNavigate, useParams } from 'react-router-dom';
import { useNavbar } from '../hooks/use-navbar';
import { useClient } from '../hooks/use-client';
import { LoadingComponent } from './Loading';
import IconeVehicle from './IconeVehicle';
import { PullStatus } from 'antd-mobile/es/components/pull-to-refresh';

const urlLastMonthServices = (cid: number) => `${process.env.REACT_APP_BACKEND_HOST}/api2/interventions?filter=client_id||eq||${cid}`;

type Filters = {
  search: string;
  buttonsVTypeSelected: { [key: string]: boolean };
  buttonsPrestationSelected: {
    exterior?: boolean;
    disinfection?: boolean;
    interior?: boolean;
    decontamination?: boolean;
    package?: boolean;
  };
};

const Client: FC = () => {
  const onClickSearch = () => {
    setFilters({ search: '', buttonsVTypeSelected: {}, buttonsPrestationSelected: {} });
    setShowSearch((showSearch) => !showSearch);
  };

  const navbar = useNavbar();
  const navigate = useNavigate();
  const { clientId } = useParams<'clientId'>();
  let client: ClientJson | undefined;

  if (clientId) {
    const data = useClient(+clientId);
    client = data.client;
  }

  const [loading, setLoading] = useState(true);
  const [interventions, setInterventions] = useState<InterventionJson[]>([]);

  const [showSearch, setShowSearch] = useState(false);
  const [filters, setFilters] = useState<Filters>({
    search: '',
    buttonsVTypeSelected: {},
    buttonsPrestationSelected: {}
  });

  function fetchData(clientId: number) {
    setLoading(true);

    fetch(urlLastMonthServices(clientId), { headers: { version: '' + process.env.REACT_APP_VERSION } })
      .then((res) => res.json())
      .then((data) => {
        setInterventions(data.data);
        setLoading(false);
      })
      .catch((error) => {
        throw Error(error);
      });
  }

  useEffect(() => {
    if (client) {
      fetchData(+client.id);
    }
  }, [client]);

  useEffect(() => {
    if (!navbar || !client) {
      return;
    }
    const returnToHome = () => {
      navigate('/');
    };

    navbar.setTitle(client.name);
    navbar.setBackArrow(true);
    navbar.setOnBack(() => navigate(-1));
    navbar.setRight(
      <div style={{ fontSize: 24 }}>
        <Space style={{ '--gap': '16px' }}>
          <SearchOutline onClick={onClickSearch} />
        </Space>
      </div>
    );
  }, [client]);

  // const onCancel = () => setAddingIntervention(false);
  // const onSave = (intervention: InterventionType) => {
  //   interventions.unshift(intervention);
  //   setAddingIntervention(false);
  //   setInterventions(interventions);
  // };

  // const handleClose = () => {
  //   const newFilters = { ...filters };
  //   newFilters.buttonsPrestationSelected = {};
  //   newFilters.buttonsVTypeSelected = {};
  //   newFilters.search = '';
  //   setFilters(newFilters);
  //   openSearchBox(false);
  //   setNavbarHeight(60);
  // };

  // const handleOpen = () => {
  //   openSearchBox(true);
  //   setNavbarHeight(120);
  // };

  const statusRecord: Record<PullStatus, string> = {
    pulling: 'Recharger',
    canRelease: 'Lacher pour recharger',
    refreshing: 'Chargement...',
    complete: 'Terminée'
  };

  const typesOfVehicle = [...new Set(interventions.map((intervention) => intervention.prestation_costs.title))];
  const distinctPrestation = interventions.reduce(
    (acc, intervention) => {
      acc.exterior = acc.exterior || intervention.exterior;
      acc.disinfection = acc.disinfection || intervention.disinfection;
      acc.interior = acc.interior || intervention.interior;
      acc.decontamination = acc.decontamination || intervention.decontamination;
      acc.package = acc.package || intervention.package;
      return acc;
    },
    {
      exterior: false,
      disinfection: false,
      interior: false,
      decontamination: false,
      package: false
    }
  );

  const interventionFiltered = interventions.filter((i) => {
    // exclude by search string
    if (filters.search.length) {
      const parcNumberMatch = i?.parc_number.toLowerCase().includes(filters.search.toLowerCase());
      const immatMatch = i?.immatriculation.toLowerCase().includes(filters.search.toLowerCase());
      if (!parcNumberMatch && !immatMatch) {
        return false;
      }
    }
    // exclude by prestation
    if (
      (filters.buttonsPrestationSelected.exterior && !i.exterior) ||
      (filters.buttonsPrestationSelected.disinfection && !i.disinfection) ||
      (filters.buttonsPrestationSelected.interior && !i.interior) ||
      (filters.buttonsPrestationSelected.decontamination && !i.decontamination) ||
      (filters.buttonsPrestationSelected.package && !i.package)
    ) {
      return false;
    }

    const isTypeSelected = Object.keys(filters.buttonsVTypeSelected).reduce((acc, t) => {
      // t = VUL
      return acc && (!filters.buttonsVTypeSelected[t] || i.prestation_costs.title === t);
    }, true);

    return isTypeSelected;
  });

  const months = interventionFiltered.reduce<string[]>((acc, intervention) => {
    if (!acc.includes(monthYear(intervention))) {
      acc.push(monthYear(intervention));
    }
    return acc;
  }, []);

  if (loading) {
    return <LoadingComponent />;
  }
  return (
    <>
      {showSearch && (
        <Card className="dark-background">
          <SearchBar
            placeholder="Immat. Parc n°"
            onChange={(val) => setFilters({ ...filters, search: val })}
            onClear={() => setFilters({ ...filters, search: '' })}
          />
          <Space>
            {typesOfVehicle.map((t) => {
              const isSelected = t in filters.buttonsVTypeSelected && filters.buttonsVTypeSelected[t];
              return (
                <div
                  key={t}
                  onClick={() => {
                    const newFilters = { ...filters };
                    newFilters.buttonsVTypeSelected[t] = !isSelected;
                    setFilters(newFilters);
                  }}
                >
                  <IconeVehicle isSelected={isSelected} title={t} />
                </div>
              );
            })}

            {(Object.keys(distinctPrestation) as Array<PrestationEnum>).map((t) => {
              const isSelected: boolean = filters.buttonsPrestationSelected[t] ?? false;
              if (distinctPrestation[t]) {
                return (
                  <IconeVehicle
                    key={t}
                    isSelected={isSelected}
                    handleClick={() => {
                      const newFilters = { ...filters };
                      newFilters.buttonsPrestationSelected[t] = !isSelected;
                      setFilters(newFilters);
                    }}
                    title={t}
                  />
                );
              }
              return '';
            })}
          </Space>
        </Card>
      )}
      {months.length === 0 ? (
        <Empty />
      ) : (
        <div style={{ height: window.innerHeight - 110 }}>
          <PullToRefresh
            renderText={(status) => {
              return <div>{statusRecord[status]}</div>;
            }}
            onRefresh={async () => {
              if (client) {
                fetchData(client.id);
              }
            }}
          >
            <IndexBar sticky={true} style={{ background: '#f5f5f5' }} className="interventions-list">
              {months.map((month) => {
                return (
                  <IndexBar.Panel index={month} title={``} key={`${month}`}>
                    <List key={month} mode="card">
                      {interventionFiltered
                        .filter((i) => monthYear(i) === month)
                        .map((intervention) => (
                          <InterventionListItem
                            key={intervention.id}
                            intervention={intervention}
                            onDelete={() => {
                              setInterventions((lastInterventions) => lastInterventions.filter((i) => i.id != intervention.id));
                            }}
                          />
                        ))}
                    </List>
                  </IndexBar.Panel>
                );
              })}
            </IndexBar>
          </PullToRefresh>
        </div>
      )}
      <FloatingBubble
        style={{
          '--initial-position-bottom': '74px',
          '--initial-position-right': '24px',
          '--edge-distance': '24px'
        }}
        onClick={() => navigate(`/client/${client?.id}/new`)}
      >
        <AddCircleOutline fontSize={32} />
      </FloatingBubble>
    </>
  );
};

export default Client;
