import { Space, Tag } from 'antd-mobile';
import { Dialog, List, SwipeAction, Toast, Image } from 'antd-mobile';

import { SwipeActionRef } from 'antd-mobile/es/components/swipe-action';
import React, { FC, useRef } from 'react';
import IconeVehicle from './IconeVehicle';
import { InterventionJson } from './types';
import { useNavigate, useParams } from 'react-router-dom';

import { DeleteOutline, EditSOutline } from 'antd-mobile-icons';

const InterventionListItem: FC<{ intervention: InterventionJson; onDelete: () => void }> = ({ intervention, onDelete }) => {
  const ref = useRef<SwipeActionRef>(null);
  const navigate = useNavigate();
  const prestations = (
    <Space>
      {intervention.exterior ? (
        <Tag color="primary" fill="outline" style={{ fontSize: '12px', padding: '5px 12px' }}>
          Ext
        </Tag>
      ) : (
        ''
      )}
      {intervention.disinfection ? (
        <Tag color="primary" fill="outline" style={{ fontSize: '12px', padding: '5px 12px' }}>
          Desinf
        </Tag>
      ) : (
        ''
      )}
      {intervention.interior ? (
        <Tag color="primary" fill="outline" style={{ fontSize: '12px', padding: '5px 12px' }}>
          Int
        </Tag>
      ) : (
        ''
      )}
      {intervention.decontamination ? (
        <Tag color="primary" fill="outline" style={{ fontSize: '12px', padding: '5px 12px' }}>
          Dec
        </Tag>
      ) : (
        ''
      )}
      {intervention.package ? (
        <Tag color="primary" fill="outline" style={{ fontSize: '12px', padding: '5px 12px' }}>
          Pack
        </Tag>
      ) : (
        ''
      )}
    </Space>
  );
  return (
    <SwipeAction
      rightActions={[
        {
          key: 'edit',
          text: <EditSOutline>Edit</EditSOutline>,
          color: 'warning',
          onClick: async () => {
            ref.current?.close();
            navigate(`/client/${intervention.client_id}/intervention/${intervention.id}`);
          }
        }
      ]}
      leftActions={[
        {
          key: 'delete',
          text: <DeleteOutline fontSize={22} />,
          color: 'danger',
          onClick: async () => {
            await Dialog.confirm({
              cancelText: 'Annuler',
              confirmText: 'Supprimer',
              content: "Supprimer l'intervention ？",
              onConfirm: () => {
                // todo remove intervention id
                fetch(`${process.env.REACT_APP_BACKEND_HOST}/api2/interventions/${intervention.id}`, {
                  headers: { version: '' + process.env.REACT_APP_VERSION },
                  method: 'DELETE'
                })
                  .then((res) => res.json())
                  .then((res) => {
                    if (res.affected == 1) {
                      onDelete();
                      Toast.show({
                        icon: 'success',
                        content: 'Interv. supprimé'
                      });
                    }
                  });
              }
            });
            ref.current?.close();
          }
        }
      ]}
    >
      <List.Item
        prefix={<IconeVehicle backgroundColor="#002d4c" title={intervention.prestation_costs.title} />}
        description={prestations}
        extra={
          <>
            <b>{new Intl.DateTimeFormat('fr', { month: '2-digit', day: '2-digit' }).format(Date.parse(intervention.timestamp))}</b>
            <br />
            {new Intl.DateTimeFormat('fr', { hour: '2-digit', minute: '2-digit' }).format(Date.parse(intervention.timestamp))}
          </>
        }
      >
        {intervention.immatriculation ? intervention.immatriculation : ''}
        {intervention.parc_number ? (intervention.immatriculation ? ' - ' : '') + intervention.parc_number : ''}
      </List.Item>
    </SwipeAction>
  );
};

export default InterventionListItem;
