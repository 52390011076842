import { useEffect, useState } from 'react';
import { ClientJson, RateJson } from '../components/types';

export type ApiClientResponse = {
  client: ClientJson | undefined;
  costs: RateJson[] | undefined;
  // error: any;
  loading: boolean;
};

export const useClient = (id: number | undefined | string): ApiClientResponse => {
  const [client, setClient] = useState<ClientJson>();
  const [costs, setCosts] = useState<RateJson[]>([]);
  const [loading, setLoading] = useState(false);

  async function fetchData(clientId: number) {
    setLoading(true);

    const data = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/api2/clients/${clientId}`, {
      headers: { version: '' + process.env.REACT_APP_VERSION }
    })
      .then((res) => res.json())
      .then((data) => {
        return data;
      })
      .catch((error) => {
        throw Error(error);
      });
    setClient(data);

    const data2 = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/api2/rates/clients/${clientId}`, {
      headers: { version: '' + process.env.REACT_APP_VERSION }
    })
      .then((res) => res.json())
      .then((data) => {
        return data;
      })
      .catch((error) => {
        throw Error(error);
      });

    setCosts(data2);

    setLoading(false);
  }
  useEffect(() => {
    if (id) {
      fetchData(+id);
    }
  }, [id]);

  return { client, costs, loading };
};
