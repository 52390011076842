import React, { FC, useEffect, useState } from 'react';

import { useNavbar } from '../hooks/use-navbar';
import { ClientJson, UserJson } from './types';
import dayjs, { Dayjs } from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import fr from 'dayjs/locale/fr';
import { Empty, List, Space, Tag } from 'antd-mobile';
import { LeftOutline, RightOutline } from 'antd-mobile-icons';
import { useNavigate } from 'react-router-dom';

dayjs().locale(fr);
dayjs.extend(weekOfYear);

export enum UserPresence {
  WORKING = 'working',
  CP = 'cp',
  ABS = 'absence'
}

// un jour
export type PlanningDayJson = {
  presence: UserPresence;
  year: number;
  week: number;
  startAt: string | null;
  endAt: string | null;
  comment: string | null;
  id: number;
  dow: number;
  clients: ClientJson[];
  agent: UserJson;
};

export type TypePlanningAgent = {
  year: number;
  week: number;
  days: PlanningDayJson[];
  agent: UserJson;
};

function fetchWeekPlanning(day: Dayjs): Promise<TypePlanningAgent> {
  return fetch(`${process.env.REACT_APP_BACKEND_HOST}/api2/plannings/?filter=week||eq||${day.week()}&filter=year||eq||${day.year()}`)
    .then((res) => res.json())
    .then((data) => {
      const raw: PlanningDayJson[] = data.data;
      const result: TypePlanningAgent[] = raw.reduce((acc, aDay) => {
        if (acc[aDay.agent.id]) {
          acc[aDay.agent.id].days[aDay.dow] = aDay;
          return acc;
        }
        const days: PlanningDayJson[] = [...Array(7)].map((_, i) => {
          return {
            agent: aDay.agent,
            dow: i,
            year: aDay.year,
            week: aDay.week,
            startAt: aDay.startAt,
            endAt: aDay.endAt,
            comment: aDay.comment,
            id: 0,
            clients: [],
            presence: UserPresence.WORKING
          };
        });
        days[aDay.dow] = aDay;
        acc[aDay.agent.id] = {
          year: aDay.year,
          week: aDay.week,
          days: days,
          agent: aDay.agent
        };
        return acc;
      }, [] as TypePlanningAgent[]);
      return result.pop() as TypePlanningAgent;
    });
}

const Planning: FC = () => {
  const navigate = useNavigate();
  const navbar = useNavbar();
  const [mainDay, setDay] = useState<Dayjs>(dayjs().startOf('week'));
  const [weekPlanning, setWeekPlanning] = useState<TypePlanningAgent>();
  const [refreshInterval, setRefreshInterval] = useState(1000 * 60 * 5);

  useEffect(() => {
    if (navbar) {
      navbar.setTitle('Planning');
      navbar.setBackArrow(false);
      navbar.setRight(
        <div style={{ fontSize: 24 }}>
          <Space style={{ '--gap': '16px' }}>
            <LeftOutline
              onClick={() => {
                setDay((mainDay) => mainDay.subtract(1, 'week'));
              }}
            />
            <RightOutline
              onClick={() => {
                setDay((mainDay) => mainDay.add(1, 'week'));
              }}
            />
          </Space>
        </div>
      );
    }
  }, []);

  const loadPlanning = async () => {
    const result = await fetchWeekPlanning(mainDay);
    console.log('refetch planning', { weekPlanning: result });

    setWeekPlanning(result);
  };
  useEffect(() => {
    loadPlanning();
  }, [mainDay]);

  useEffect(() => {
    if (refreshInterval && refreshInterval > 0) {
      const interval = setInterval(loadPlanning, refreshInterval);
      return () => clearInterval(interval);
    }
  }, [refreshInterval]);

  const daysOfWeek = [
    'Dimanche ' + mainDay.add(0, 'day').format('DD/MM'),
    'Lundi ' + mainDay.add(1, 'day').format('DD/MM'),
    'Mardi ' + mainDay.add(2, 'day').format('DD/MM'),
    'Mercredi ' + mainDay.add(3, 'day').format('DD/MM'),
    'Jeudi ' + mainDay.add(4, 'day').format('DD/MM'),
    'Vendredi ' + mainDay.add(5, 'day').format('DD/MM'),
    'Samedi ' + mainDay.add(6, 'day').format('DD/MM')
  ];

  return weekPlanning ? (
    <>
      {weekPlanning.days.map((day) => {
        return (
          // <Card title={daysOfWeek[day.dow]} key={daysOfWeek[day.dow]}>

          <List
            mode="card"
            header={
              <Space>
                {daysOfWeek[day.dow]}
                {day.startAt || day.endAt ? (
                  <Tag color={'danger'}>
                    {day.startAt ? day.startAt.split(':').splice(0, 2).join('h') : '-'}
                    {' -> '}
                    {day.endAt ? day.endAt.split(':').splice(0, 2).join('h') : '-'}
                  </Tag>
                ) : (
                  ''
                )}
              </Space>
            }
            key={day.dow}
          >
            {day.comment && <List.Item style={{ backgroundColor: 'rgb(245, 176, 0)' }}>{day.comment}</List.Item>}
            {day.clients.map((client) => (
              <List.Item
                onClick={() => {
                  navigate(`/client/${client.id}`);
                }}
                key={client.id}
              >
                {client.name}
              </List.Item>
            ))}
          </List>
          // </Card>
        );
      })}
    </>
  ) : (
    <Empty />
  );
  // </PullToRefresh>
};
export default Planning;
