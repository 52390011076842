import React, { FC } from 'react';
import { DotLoading } from 'antd-mobile';

export const LoadingComponent: FC = () => (
  <div
    style={{
      fontSize: 24,
      flex: 1,
      alignContent: 'center',
      justifyContent: 'center',
      alignItems: 'stretch',
      flexDirection: 'column',
      display: 'flex',
      height: '70vh',
      textAlign: 'center'
    }}
  >
    {/* <Logo width={200} /> */}
    <DotLoading />
  </div>
);
