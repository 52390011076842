import React, { FC, useEffect, useState } from 'react';

import { IndexBar, List } from 'antd-mobile';
import { ClientJson } from './types';

const ClientList: FC<{ onChooseClient: (client: ClientJson) => void; search: string }> = ({ onChooseClient, search }) => {
  const [clients, setClients] = useState<ClientJson[]>([]);
  const [recentClients, setRecentClients] = useState<ClientJson[]>([]);

  useEffect(() => {
    let isMounted = true; // note mutable flag

    fetch(`${process.env.REACT_APP_BACKEND_HOST}/api2/clients`, { headers: { version: '' + process.env.REACT_APP_VERSION } })
      .then((res) => res.json())
      .then((data) => {
        if (isMounted) setClients(data);
      })
      .catch(console.log);

    fetch(`${process.env.REACT_APP_BACKEND_HOST}/api2/clients/recent`, { headers: { version: '' + process.env.REACT_APP_VERSION } })
      .then((res) => res.json())
      .then((data) => {
        if (isMounted) setRecentClients(data);
      })
      .catch(console.log);

    return () => {
      isMounted = false;
    };
  }, []);

  const clientsFiltered = clients && clients.filter((c) => c.name.toLowerCase().includes(search?.toLowerCase()));

  const charCodeOfA = 'A'.charCodeAt(0);

  const alphaGroups = Array(26)
    .fill('')
    .map((_, i) => ({
      title: String.fromCharCode(charCodeOfA + i),
      key: String.fromCharCode(charCodeOfA + i),
      items: clientsFiltered.filter((c) => c.name[0] === String.fromCharCode(charCodeOfA + i))
    }))
    .filter((g) => g.items.length > 0);
  const groups = [
    { title: 'Recents', items: recentClients.filter((c) => c.name.toLowerCase().includes(search?.toLowerCase())), key: '#' },
    ...alphaGroups
  ];
  return (
    <>
      <IndexBar sticky={true} className="dark-background">
        {groups.map((group) => {
          const { title, items, key } = group;
          return (
            <IndexBar.Panel index={key} title={`${title}`} key={`${title}`}>
              <List mode={'card'}>
                {items.map((item, index) => (
                  <List.Item
                    key={index}
                    onClick={() => {
                      onChooseClient(item);
                    }}
                  >
                    {item.name}
                  </List.Item>
                ))}
              </List>
            </IndexBar.Panel>
          );
        })}
      </IndexBar>
    </>
  );
};
export default ClientList;
