import React, { FC, useEffect } from 'react';
import { Route, BrowserRouter, Outlet, Routes } from 'react-router-dom';
import Login from './Login';

import { useAuth } from '../hooks/use-auth';
import { NavBar } from 'antd-mobile';

import Home from './Home';
import Client from './Client';
import { useNavbar } from '../hooks/use-navbar';
import Search from './Search';
import NewIntervention from './NewIntervention';
import Account from './Account';
import Photo from './Photo';
import { UserJson } from './types';
import { hasFeature } from './featureflag';
import { BottomBar } from './BottomBar';
import Sign from './Sign';
import Planning from './Planning';

const MyNavBar: FC = () => {
  const navbar = useNavbar();
  return (
    <NavBar
      back={navbar?.back}
      backArrow={navbar?.backArrow}
      onBack={() => {
        navbar && navbar.onBack && navbar.onBack();
      }}
      right={navbar?.right}
    >
      {navbar?.title}
    </NavBar>
  );
};

const Main: FC<any> = () => {
  const auth = useAuth();
  useEffect(() => {
    auth?.autoSignIn();
  }, []);

  function Layout() {
    return (
      <div className="app">
        <div className="top">
          <MyNavBar />
        </div>
        <div className="body">
          <div style={{ height: window.innerHeight - (46 + 51) }}>
            <Outlet />
          </div>
        </div>
        <div className="bottom">
          <BottomBar features={hasFeature(auth?.user as UserJson)} />
        </div>
      </div>
    );
  }

  return auth?.user ? (
    <BrowserRouter basename="/agent">
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="search" element={<Search />} />
          <Route path="me" element={<Account />} />
          <Route path="photo" element={<Photo />} />
          <Route path="sign" element={<Sign />} />
          <Route path="planning" element={<Planning />} />

          <Route path="client/:clientId" element={<Client />} />
          <Route path="client/:clientId/new" element={<NewIntervention />} />
          <Route path="client/:clientId/intervention/:interventionId" element={<NewIntervention />} />
        </Route>
      </Routes>
    </BrowserRouter>
  ) : auth?.user === false ? (
    <Login />
  ) : (
    <></>
  );
};

export default Main;
