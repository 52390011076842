import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { onServiceWorkerUpdate } from '@3m1/service-worker-updater';

// Sentry.init({
//   environment: process.env.REACT_ENVIRONMENT || 'production',
//   dsn: "https://b3e28b232ad148f3936ef989bf8494fa@o473916.ingest.sentry.io/5509452",
//   integrations: [
//     new Integrations.BrowserTracing(),
//   ],

//   // We recommend adjusting this value in production, or using tracesSampler
//   // for finer control
//   tracesSampleRate: 1.0,
// });

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorkerRegistration.register({
  onUpdate: onServiceWorkerUpdate
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
